import { message } from "antd";
import axios from "axios"
import { AUTH_TOKEN } from "constants/AuthConstant";

export class APIService {
  static baseURL = process.env.REACT_APP_API_URL;

  static GET_ROUTES = {
    GENERAL_INFO: '/dashboard/generalInfo',
    ALL_BUYERS: '/buyer/all',
    ORDERS_PER_MONTH: '/order/perMonth',
    REPORT_BUYER_WITH_PRODUCTS: '/reports/buyerWithProducts',
    GET_BUYERS_RESUME_REPORT: '/reports/buyerResumeReport',
    GET_TOTAL_PRICES: '/reports/totalPricesReport',
  }

  static POST_ROUTES = {
    ALL_PRODUCTS: '/product',
    GET_PRODUCT: '/product/getProduct',
    ADD_PRODUCT: '/product/add',
    GET_OUT_OF_STOCK_PRODUCTS: '/product/productsOutOfStock',
    GET_REPORT_SHHEET: '/product/generateReportSheet',
    ALL_ORDERS: '/order',
    ADD_BUYER: '/buyer/add',
    ADD_ORDER: '/order/add',
    GET_ORDER: '/order/one',
    GENERATE_ORDERS_RESUME_REPORT: '/reports/generateOrdersResumeCSV',
    GET_ORDERS_RESUME_REPORT: '/reports/ordersResume',
    SIGN_IN: '/auth/login',
    GET_NOTIFICATIONS: '/notification',
    SET_SEEN_NOTIFICATIONS: '/notification/seenNotifications',
    ADD_NOTIFICATION: '/notification/add',
  }

  static PUT_ROUTES = {
    UPDATE_ORDER_STATUS: '/order/editStatus',
    UPDATE_PAID_STATUS: '/order/paidStatus',
    UPDATE_PRODUCT: '/product/edit',
    UPDATE_ORDER: '/order/edit',
  }

  static DELETE_ROUTES = {
    DELETE_PRODUCT: '/product',
    DELETE_ORDER: '/order',
    DELETE_NOTIFICATION: '/notification'
  }

  static doGetRequest = async (url) => {
    try {
      const resp = await axios.get(`${this.baseURL}${url}`, { headers: { Authorization: localStorage.getItem(AUTH_TOKEN) }});
      return resp?.data;
    } catch (err) {
      if (err.response.status === 401) {
        message.error('Lo sentimos, no tienes permisos para acceder a esta sección');
      }
      return `Hubo un error > GET > ${url}`;
    }
  }

  static doPostRequest = async (url, body, config = {}) => {
    try {
      const resp = await axios.post(`${this.baseURL}${url}`, body, {
        ...config,
        headers: {
          Authorization: localStorage.getItem(AUTH_TOKEN)
        }
      });
      return resp?.data;
    } catch (err) {
      if (err.response.status === 401) {
        message.error('Lo sentimos, no tienes permisos para acceder a esta sección');
      }
      return `Hubo un error > POST > ${url}`;
    }
  }

  static doPutRequest = async (url, body) => {
    try {
      const resp = await axios.put(`${this.baseURL}${url}`, body, {
        headers: {
          Authorization: localStorage.getItem(AUTH_TOKEN)
        }
      });
      return resp?.data;
    } catch (err) {
      if (err.response.status === 401) {
        message.error('Lo sentimos, no tienes permisos para acceder a esta sección');
      }
      return `Hubo un error > PUT > ${url}`;
    }
  }

  static doDeleteRequest = async (url, body) => {
    try {
      const resp = await axios.delete(`${this.baseURL}${url}`, { data: body, headers: {
        Authorization: localStorage.getItem(AUTH_TOKEN)
      } });
      return resp?.data;
    } catch (err) {
      if (err.response.status === 401) {
        message.error('Lo sentimos, no tienes permisos para acceder a esta sección');
      }
      return `Hubo un error > DELETE > ${url}`;
    }
  }
}