import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIService } from 'utils/services/APIService';
import moment from 'moment';

export const initialState = {
	data: [],
  loading: false,
}

export const getNotifications = createAsyncThunk('notification/get', async (data, { rejectWithValue }) => {
	try {
		const response = await APIService.doPostRequest(APIService.POST_ROUTES.GET_NOTIFICATIONS);
		if (!response.error) {
      const notifications = response?.data || [];
      const notificationsParsed = notifications.map((notification) => {
        return {
          id: notification._id,
					name: notification.title,
					desc: notification.description,
          read: notification.read,
          time: notification.date,
				}
      })
			return notificationsParsed;
		} else {
			return rejectWithValue(`Ha ocurrido un error > ${response.error}`);
		}
	} catch (err) {
		return rejectWithValue("Error");
	}
})

export const addNotification = createAsyncThunk('notification/add', async (data, { rejectWithValue }) => {
  try {
    const dataToSend = {
      title: data.name,
      description: data.desc,
    }
    const resp = await APIService.doPostRequest(APIService.POST_ROUTES.ADD_NOTIFICATION, dataToSend);
    const id = resp?.data?._id;
    data.id = id;
    data.time = moment().format('YYYY-MM-DD HH:mm:ss');
    return data;
  } catch (err) {
    console.log(err, 'err');
    return rejectWithValue("Error");
  }
});

export const markAsRead = createAsyncThunk('notification/markAsRead', async (id, { rejectWithValue }) => {
  try {
    return id;
  } catch (err) {
    return rejectWithValue("Error");
  }
});


export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		getNotificationsLoading: (state) => {
			state.loading = true
		},
		getNotificationsSuccess: (state, action) => {
			state.loading = false
			state.data = action.payload
		},
    addNotificationLoading: (state) => {
      state.loading = true
    },
    addNotificationSuccess: (state, action) => {
      state.loading = false
      state.data = [...state.data, action.payload]
    },
    markAsReadLoading: (state) => {
      state.loading = true
    },
    markAsReadSuccess: (state, action) => {
      state.loading = false
      state.data = state.data.map((notification) => {
        if (notification.id === action.payload) {
          notification.read = true;
        }
        return notification;
      })
    },
	},
	extraReducers: (builder) => {
		builder
			.addCase(getNotifications.pending, (state) => {
				state.loading = true
			})
			.addCase(getNotifications.fulfilled, (state, action) => {
				state.loading = false
        state.data = action.payload
			})
			.addCase(getNotifications.rejected, (state, action) => {
				state.loading = false
			})
      .addCase(addNotification.pending, (state) => {
        state.loading = true
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.loading = false
        state.data = [...state.data, action.payload]
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(markAsRead.pending, (state) => {
        state.loading = true
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        state.loading = false
        state.data = state.data.map((notification) => {
          if (notification.id === action.payload) {
            notification.read = true;
          }
          return notification;
        })
      })
      .addCase(markAsRead.rejected, (state, action) => {
        state.loading = false
      })
	},
})

export const {
  getNotificationsSuccess,
  addNotificationSuccess,
} = notificationSlice.actions

export default notificationSlice.reducer